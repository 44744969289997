
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class Documentation extends Vue {
    offsetTop = 0;
    onScroll(e) {
        this.offsetTop = e.target.scrollTop;
    }
}
